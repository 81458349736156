import axios from "axios";
import { getToken, removeToken } from "./cookie";
// import { Loading } from 'element-ui';
import { Message } from "element-ui";
import store from "@/vuex/store";
import Vue from "vue";
let vue = new Vue();
// import Vue from 'vue'
// import App from '../App.vue'
// import router from '@/router'
// var vm = new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

const reqAxios = axios.create({
  // baseURL: "http://192.168.1.3:8081",
  // baseURL: "http://192.168.1.3:8080",
  // baseURL: "http://123.60.170.219:8082",
  // baseURL: "http://localhost:8082",
  //baseURL: "http://192.168.1.39:8082",


//baseURL:'http://1.92.85.51:8080',
// baseURL:'http://3.92.67.237:8080',
baseURL:'http://38.12.33.18:8080/',
  
  //  baseURL: "http://beecsgo.com/prod-api/",
  //  baseURL: "http://www.a8cs2.com/prod-api/",
  // baseURL: 'http://127.0.0.1/prod-api',
  // baseURL: "http://101.200.132.56/prod-api/",

  timeout: 60000, // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
});
// 拦截请求（拦截器）
const timer = new Map();
reqAxios.interceptors.request.use(
  function (request) {
    if (request.url !== "/api/luckyUpgrade/getUpgradeRecord") {
      vue.$toast("加载中");
    }
    // 处理请求公共数据，如添加请求头令牌...
    let token = getToken();
    //请求头设置
    request.headers = {
      "content-type": "application/json;",
      userType: "user",
      Authorization: token,
    };
    // // console.log('request',request);
    if (request.url == "/api/luckyUpgrade/upgrade") {
      return throttle(request, 3500);
    }
    if (
      request.url == "/api/luckyUpgrade/getUpgradeRecord" ||
      request.url == "/api/roll/getRollList" ||
      request.url == "/api/userAmountRecords/blendErcashRank" ||
      request.url == "/api/boxRecords/historyByCondition" ||
      request.url == "/api/userAmountRecords/deliveryRecords" ||
      request.url == "/api/userAmountRecords/userAccountDetail" ||
      request.url == "/api/userPackSack/getPackSack"||
      request.url == "/api/roll/getRollPlayers"
    ) {
      return request;
    }
    return throttle(request, 500);
    // return request;
  },
  function (error) {
    console.log("requesterror", error);
    return Promise.reject(error);
  }
);
function throttle(request, time) {
  // console.log(request);
  let lastTime = timer.get(request.url);
  let nowTime = new Date().getTime();
  if (request.method == "get") {
    return request;
  } else {
    if (lastTime && nowTime - lastTime < time) {
      console.log("overRequest", request);
      return Promise.reject("请勿频繁操作");
      // return false
    } else {
      timer.set(request.url, nowTime);
      return request;
    }
  }
}

//响应拦截器
reqAxios.interceptors.response.use(
  (res) => {
    // console.log('response',res)
    vue.$hide();
    if (res.data.code == 401) {
      Message({
        message: "登录已过期,请重新登陆",
        type: "warning",
        customClass: "log_warning",
      });
      removeToken();
      store.commit("LOGIN_IS_SHOW", true);
      store.commit("ADVERT_IS_SHOW", true);
      return;
    } else if (res.status == 200) {
      return res;
    } else if (res.data.code == 200) {
      return res;
    } else {
      Message({
        message: res.data.msg,
        type: "warning",
        customClass: "log_warning",
      });
      return res;
    }
  },
  (err) => {
    console.log("err", err);
    if (err == "请勿频繁操作") {
      Message({
        message: "您的操作太频繁，请1秒后再试",
        type: "warning",
        customClass: "log_warning",
      });
    }
    if (err.response.status == 500) {
      Message({
        message: err.response.data.message,
        type: "warning",
        customClass: "log_warning",
      });
    }
    if (err.response.status == 401) {
      removeToken();
      store.commit("LOGIN_IS_SHOW", true);
      store.commit("ADVERT_IS_SHOW", true);
      Message({
        message: "登录过期，请重新登陆",
        type: "warning",
        customClass: "log_warning",
      });
    }
  }
);

//登录
export const getLogin = (data) => {
  return reqAxios.post("/api/login", data);
};
//退出登录
export const logOut = () => {
  return reqAxios.post("/api/logout");
};
//注册
export const getRegister = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/register",
    data,
  });
};

// 实名认证
export const realNameAuthentication = (data) => {
  return reqAxios({
    url: `/api/user/realNameAuthentication`,
    method: "post",
    data: data,
  });
};

//获取下级人数
export const getUserSubusser = () => {
  return reqAxios({
    url: "/api/user/rechargeCount",
    method: "get",
  });
};
export const getUserSubRechange = () => {
  return reqAxios({
    url: "/api/user/getOrdersAmounts",
    method: "get",
  });
};

// 判断是否充值
export const IsRecharge = () => {
  return reqAxios.get("/api/user/getUserIsRecharge");
};

// 刷新用户金币
export const updateUserData = () => {
  return reqAxios.post("/api/user/updateUserData");
};

// 获取用户信息
export const getUserInfo = () => {
  return reqAxios.get("/api/getInfo");
};

//roll房列表
export const getRollList = (data) => {
/*   return reqAxios.get("/api/roll/getRollList",data); */
  return reqAxios({
    url: `/api/roll/getRollList`,
    method: "get",
    params: data,
  });
};
//获取roll房信息
export const getRollDetial = (rollId) => {
  return reqAxios({
    url: `/api/roll/getRollDetails/${rollId}`,
    method: "get",
  });
};

//加入roll房
export const joinRoll = (rollId, rollPassword = "") => {
  return reqAxios({
    url: `/api/roll/joinRoll`,
    method: "post",
    data: {
      rollId,
      rollPassword,
    },
  });
};

// 获取用户参与Roll房
// export const getMyPartRollList = (data) => {
//   return reqAxios({
//     url: `/api/roll/getRollList`,
//     method: "get",
//     params: data,
//   });
// };

// 个人明细
export const getUserLogList = (data) => {
  return reqAxios({
    url: `/api/userAmountRecords/userAccountDetail`,
    method: "post",
    data,
  });
};
//可充值钱数
export const getRechargelist = (data) => {
  return reqAxios({
    url: `/api/recharge/list`,
    method: "get",
    params: data,
  });
};
//充值记录
export const orderList = () => {
  return reqAxios({
    url: `/admin/order/list`,
    method: "get",
  });
};
//
export const jiuJiaPay = (data) => {
  return reqAxios({
    url: `/api/jiuJiaPay/createPay`,
    method: "post",
    data: data,
  });
};
//
export const kamiPay = (data) => {
  return reqAxios({
    url: `/api/recharge/cardPay`,
    method: "post",
    params: data,
  });
};

// 签到
export const attendance = () => {
  return reqAxios({
    url: `/api/attendanceRecord/attendance`,
    method: "get",
  });
};
// 七日签到
export const attendance7day = () => {
  return reqAxios({
    url: `/api/attendanceRecord/sevenAttendance`,
    method: "get",
  });
};

//当日排行榜
export const creditsRank = (data) => {
  return reqAxios({
    url: `api/userCreditsRecords/creditsRank/2/1/5`,
    method: "get",
    params: data,
  });
};
// 获取下级流水列表
export const getUserxList = () => {
  return reqAxios({
    url: `/api/user/getLsjlList`,
    method: "get",
  });
};

// 推广
export const userCreditsRecords = (page, size) => {
  return reqAxios({
    url: `api/userAmountRecords/pWelfareRecords/${page}/${size}`,
    method: "get",
  });
};

//站内信-获取站内信列表

export const getMessageList = (data) => {
  return reqAxios({
    url: `/api/message/getMessageList`,
    method: "get",
    params: data,
  });
};

//任务列表
export const gettaskList = () => {
  return reqAxios({
    url: `/api/ttTask/taskOfme`,
    method: "get",
  });
};
//领取任务
export const claimTasks = (tid) => {
  return reqAxios({
    url: `/api/ttTask/getAward/${tid}`,
    method: "get",
  });
};

// 头像列表
export const getavatar = () => {
  return reqAxios({
    url: "/api/userAvatar/list",
    method: "get",
  });
};
//站内信-查看内容

export const getMessageView = (data) => {
  return reqAxios({
    url: `/api/message/view?id=${data}`,
    method: "get",
  });
};
//站内信-批量操作
export const getMessageOperation = (data) => {
  return reqAxios({
    url: `/api/message/batchOperation?ids=${data.ids}&status=${data.status}`,
    method: "post",
    // data: data,
  });
};

//首页顶部轮播
export const getWeapon = (options) => {
  return reqAxios({
    url: `/api/boxRecords/historyByCondition`,
    method: "POST",
    data: options,
  });
};

//首页中间轮播
export const getHomeBanner = (options) => {
  return reqAxios.get("/api/websiteSetup/getBannerList", options);
};
//获取广告
export const getAdvertisement = () => {
  return reqAxios.get("/api/advertisement/1");
};

//开箱 根据宝箱ID获取宝箱数据

//开箱 历史掉落
export const getBoxhistory = (options) => {
  return reqAxios({
    url: `/api/boxRecords/historyByCondition`,
    method: "POST",
    data: options,
  });
};

//开箱接口
export const openBox = (options) => {
  return reqAxios({
    method: "post",
    url: `/api/bindbox/openBox`,
    params: options,
  });
};

//开箱，武器分解 // 背包分解
export const decompose = (options) => {
  return reqAxios({
    method: "post",
    url: `/api/userPackSack/decompose`,
    data: options,
  });
};
// 分解记录

export const userdecompseList = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/userPackSack/decomposeLog`,
    data,
  });
};

// 提取饰品
export const delivery = (options) => {
  return reqAxios({
    method: "post",
    url: `/api/userPackSack/delivery`,
    data: options,
  });
};

//全部对战，查询所有对战列表
export const getFightList = () => {
  return reqAxios({
    url: "/api/fight/getFightList",
    method: "get",
    data: {
      page: 1,
      size: 10,
      statusList: [1, 0],
    },
  });
};

//创建对战
export const createFight = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/createFight",
    data,
  });
};
//玩家准备
/* /api/fight/seatrReady */
export const ApiSeatReady = (fightId) => {
  return reqAxios({
    url: `/api/fight/seatrReady?fightId=${fightId}`,
    method: "get",
  });
};
//房主开始游戏
export const Playthegame = (fightId) => {
  return reqAxios({
    url: `/api/fight/fightBegin?fightId=${fightId}`,
    method: "get",
  });
};
//游戏结束
/* /api/fight/fightEnd */
export const Gameover = (fightId) => {
  return reqAxios({
    method: "get",
    url: `/api/fight/fightEnd?fightId=${fightId}`,
  });
};

//保存对战回合
export const saveFightBoutData = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/saveFightBoutData",
    data: data,
  });
};
//获取对战回合
export const getFightBoutNum = (fightId) => {
  return reqAxios({
    method: "post",
    url: `/api/fight/getFightBoutNum/${fightId}`,
  });
};
//获取对战数据
export const getFightData = (fightId) => {
  return reqAxios({
    url: `/api/fight/getFightData/${fightId}`,
    method: "get",
  });
};
//获取对战结果
export const getFightResult = (fightId) => {
  return reqAxios({
    url: `/api/fight/getFightResult/${fightId}`,
    method: "get",
  });
};
//查询对战宝箱列表
export const getFightBoxList = (data) => {
  return reqAxios({
    url: `/api/fight/getFightBoxList`,
    method: "get",
    params: data,
  });
};
//加入对战的验证
export const joinFightverify = (fightId) => {
  return reqAxios({
    url: `/api/fight/joinFightRoom`,
    method: "post",
    params: fightId,
  });
};

//加入对战
export const joinFight = (data) => {
  return reqAxios({
    url: `/api/fight/joinFight`,
    method: "post",
    params: data,
  });
};
//对战宝箱饰品
export const boxOrnament = (id) => {
  return reqAxios.get(
    `/api/fightAgainstBData/getFightAgainstBoxOrnamentsById?bid=${id}`
  );
};

//我的对战
export const getMyPartFightList = (data) => {
  return reqAxios({
    url: `/api/fight/getMyPartFightList`,
    method: "get",
    params: data,
  });
};

//获取每一回合的对战结果
export const getpkrealdetial = (data) => {
  return reqAxios({
    url: "/api/fight/getFightRecord",
    method: "get",
    params: data,
  });
};

//追梦 获取饰品列表

export const deramlist = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/luckyUpgrade/getOrnamentsList`,
    params: data,
  });
};

//追梦
export const deramrecord = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/luckyUpgrade/getUpgradeRecord`,
    data,
  });
};

//追梦
export const deramupgrade = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/luckyUpgrade/upgrade`,
    data: data,
  });
};

//用户签到post
export const getUserSign = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/user/userSigin`,
    data,
  });
};

//用户签到查询
export const getUserSigndata = (data) => {
  return reqAxios.get(`/api/user/userSigninData?signDate=${data}`);
};

//发送验证码 1注册 2登录 3修改手机号 4忘记密码
export const getcode = (data) => {
  return reqAxios.post(`/api/sms/getVerifyCode`, data);
  // return reqAxios.post(`/sms/getVerifyCode?phonenumber=${data.phonenumber}&type=${data.type}`)
};

//修改密码
export const changePass = (data) => {
  return reqAxios.post("/api/user/forgetPassword", data);
};

//充值查询
export const getUserchong = () => {
  return reqAxios.get(`/api/ttCoinItem/getCoinItemList`);
};

export const Recgargezfb = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/jiujiaOrder/createOrder`,
    data,
  });
};
export const Recgargewx = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/tianxinOrder/createOrder`,
    data,
  });
};

//商城列表
export const shoplist = (options) => {
  return reqAxios({
    method: "get",
    url: `/api/shopping/list`,
    params: options,
  });
};
//商城查询条件
export const shopcondition = (value) => {
  return reqAxios({
    url: `/api/shopping/getShoppingQuery?value=` + value,
    method: "get",
  });
};

// 商城物品兑换
export const shopconvert = (options) => {
  return reqAxios({
    method: "post",
    url: `/api/shopping/exchange`,
    params: options,
  });
};
// 背包
export const getPackSack = (data) => {
  return reqAxios({
    url: `/api/userPackSack/getPackSack`,
    method: "post",
    data,
  });
};

// 汰换
export const updateReplacement = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/skinsservice/replacementRecord/synthesizeItems`,
    data,
  });
};
// 收支明细
export const getdetail = (pageSize = 15, pageNum = 1) => {
  return reqAxios.get(
    `/api/user/getBeanChangeRecords?pageSize=${pageSize}&pageNum=${pageNum}`
  );
};

// 绑定交易链接
export const giveMoneyAPI = (options) => {
  return reqAxios.post(`/api/user/bindSteamLink?steamLink=${options}`);
};
// 更新用户信息
export const updateUserDetails = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/user/updateUserDetails",
    data,
  });
};
// 更新用户头像信息
export const updataavatar = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/user/profilePictureUpload",
    data: data,
  });
};
// 绑定推广链接
export const giveUserAPI = (options) => {
  return reqAxios({
    method: "post",
    url: `/api/user/bindInvitationCode?invitationCode=${options}`,
  });
};

// 提货账单
export const getExtractBillAPI = (data) => {
  return reqAxios.get(
    `/api/user/getDeliveryRecordList?status=${data}&pageSize=1000&pageNum=1`
  );
};

//文章
export const getArticle = (type) => {
  return reqAxios.get(`/api/websiteSetup/getContentByType/?alias=${type} `);
};
//查询宝箱分类
export const boxesType = () => {
  return reqAxios.get("/api/bindbox/getBoxList");
};

///api/battleRoyale/joinRoom
export const getbigkillroom = (rankId, roomid, copies) => {
  return reqAxios.get(
    `/api/battleRoyale/joinRoom?rankId=${rankId}&roomId=${roomid}&copies=${copies}`
  );
};
export const getbigkilHistroy = (rankId) => {
  return reqAxios.get(`/api/battleRoyale/getHistoricalRecord?rankId=${rankId}`);
};

//卡密 /prod-api/api/ttCoinItem/checkKM/{key}
export const getkami = (id) => {
  return reqAxios.post(`/api/ttCoinItem/checkKM/${id}`);
};

// 查询用户金币 /api/user/getUserRealTimeBean

export const getuserbean = () => {
  return reqAxios.get(`/api/user/getUserRealTimeBean`);
};

// type 1 昨天 2 今天

export const getdiaoluo = () => {
  return reqAxios.get(`/api/user/propRankOfDay/${1}/1`);
};

// /api/user/propRankOfDay

////api/websocket/skins
//协议

export const getagreement = (id) => {
  return reqAxios.get(`/admin/content/${id}`);
};
//宝箱详情
export const simpleBoxDetail = (boxId) => {
  // return reqAxios.get(`/api/fight/simpleBoxDetail/boxId=${boxId}`);
  return reqAxios({
    method: "get",
    url: "/api/fight/simpleBoxDetail",
    params: { boxId },
    // data:{boxId}
  });
};
//观战获取房间数据
export const audience = (id) => {
  return reqAxios.get(`/api/fight/audience?fightId=${id}`);
};
//极速竞技退出房间/api/fight/fightRoomExit
export const fightRoomExit = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/fightRoomExit",
    params: data,
  });
};
//我参与的
export const fightOnMyOwn = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/fightOnMyOwn",
    data,
  });
};
//支付接口
export const ApiAddTrans = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/zyZFB/ApiAddTrans",
    params: data,
  });
};
//国富汇通
export const ApiAdddgfht = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/gfht/pay",
    data,
  });
};

//排行榜
export const blendErcashRank = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/userAmountRecords/blendErcashRank",
    data,
  });
};
//获取团队信息
export const teamUsers = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/userAmountRecords/teamUsers",
    data,
  });
};
export const changePW = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/user/changePW",
    data,
  });
};
//获取个人提货记录
export const deliveryRecords = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/userAmountRecords/deliveryRecords",
    data,
  });
};
//获取个人充值明细
export const rechargeRecords = (data) => {
  return reqAxios({
    method: "get",
    url: "/api/order/list",
    params: data,
  });
};
//历史详情
export const fightDetail = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/fightDetail",
    data,
  });
};
//roll房参与人员
export const getRollPlayers = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/roll/getRollPlayers",
    data,
  });
};
//roll房开奖详情
export const getRollOpenPrize = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/roll/getRollOpenPrize",
    data,
  });
};
//roll房奖池
export const getRollPrizePool = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/roll/getRollPrizePool",
    data,
  });
};
export const earlierHistory = (data) => {
  return reqAxios({
    method: "post",
    url: "/api/fight/earlierHistory",
    data,
  });
};
export const endRoll = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/roll/endRoll/${data}`,
  });
};
export const packSackGlobalData = () => {
  return reqAxios({
    method: "get",
    url: `/api/userPackSack/packSackGlobalData`,
  });
};

export const bindBoss = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/user/bindBoss`,
    data,
  });
};
export const receiveRedPacket = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/bonus/receiveRedPacket/${data}`,
  });
};
export const mayi = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/mayi/ApiAddTrans`,
    data,
  });
};
export const getBoxList = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/bindbox/getBoxList`,
    params:data,
  });
};
export const getBoxTypeList = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/box/getBoxTypeList/${data.page}/${data.size}`,
  });
};
/* /api/bindbox/{boxId} */
export const getDetail = (boxId) => {
  return reqAxios({
    method: "get",
    url: `/api/bindbox/${boxId}`,
  });
};
//vip等级
export const welfareList = () => { 
  return reqAxios({
    method: "get",
    url: `/api/welfare/getWelfareList`,
  });
}
///api/userAmountRecords/rechargeRanking 获取充值排行榜
export const rechargeRanking = () => { 
  return reqAxios({
    method: "get",
    url: `/api/userAmountRecords/rechargeRanking `,
  });
}
///api/userAmountRecords/rechargeRankingRewardsIntroduction
export const rechargeRankingRewardsIntroduction = () => { 
  return reqAxios({
    method: "get",
    url: `/api/userAmountRecords/rechargeRankingRewardsIntroduction`,
  });
}


// /api/user/vipLevelIntroduction
export const getviplist = () => { 
  return reqAxios({
    method: "get",
    url: `/api/user/vipLevelIntroduction`,
  });
}